<template>
  <modal name="select-user-modal" @closed="clear" :adaptive="true" height="auto"
         :maxWidth="438"
         class="modal-delete-user user-select-modal modal">
    <h3 class="modal-title">{{ title }}</h3>
    <div class="user-select-container">
      <ADropdown label="Select user" :list="users" :active="user" @selected="setUser"/>
    </div>
    <div class="modal-delete-buttons df">
      <button class="btn blue" :disabled="!user" @click="onConfirm">Confirm</button>
      <button class="btn red" @click="close">Cancel</button>
    </div>
  </modal>
</template>

<script>
import modal from '@/mixins/modal';
import ADropdown from '@/components/common/ADropdown';

export default {
  name: "UserSelect",
  components: {ADropdown},
  props: ['users', 'articles', 'title'],
  data() {
    return {
      user: null
    }
  },
  methods: {
    clear() {
      this.user = null;
    },
    close() {
      this.$modal.hide('select-user-modal');
    },
    setUser(user) {
      this.user = user;
    },
    onConfirm() {
      this.$emit('select', this.user);
      this.$modal.hide('select-user-modal');
    }
  }
}
</script>

<style lang="scss">
.user-select-modal {
  .vm--modal {
    overflow: visible;
  }
}

.user-select-container {
  .dropdown-container {
    display: block;
    text-align: left;
  }

  .dropdown-btn {
    width: 100%;
  }
}
</style>
