<template>
  <modal
    name="logins-user-modal"
    @closed="clear"
    :adaptive="true"
    height="auto"
    width="100%"
    :maxWidth="973"
    class="modal-table"
  >
    <div class="modal-table-title-container">
      <h3 class="modal-table-title">Login history</h3>
    </div>

    <simplebar class="table-scroll">
      <Preloader v-if="pending" />
      <table v-if="list.length > 0" class="logins-table">
        <thead>
        <th>Login date</th>
        <th>State+IP</th>
        <th>Region</th>
        <th>City</th>
        </thead>
        <tbody>
        <tr v-for="(session, idx) in list" :key="`session-${idx}`">
          <td>{{$moment(session.created_at).format('YYYY-MM-DD HH:MM:ss')}}</td>
          <td>
            <div v-if="session.login_data" class="table-ip-container">
              <b>IP:</b>[{{ session.login_data.country }}]
              <img
                v-if="session.login_data.flag_icon"
                :alt="session.login_data.country"
                :src="session.login_data.flag_icon"
              >
              {{session.ip}}
            </div>
            <p v-else>--</p>
          </td>
          <td>
            {{session.login_data ? session.login_data.region : '--'}}
          </td>
          <td>
            {{session.login_data ? session.login_data.city : '--'}}
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="table-empty">
        <p v-if="!pending">Empty</p>
      </div>
    </simplebar>
    <APagination
      ref="pagination"
      :without-route="false"
      :visible="list.length > 0"
      :page="page"
      :count="paginationPages"
      :callback="setPagination"
    />
  </modal>
</template>

<script>
import modal from '@/mixins/modal';
import {mapActions, mapGetters} from 'vuex';
import Preloader from '@/components/common/Preloader';

export default {
  name: 'UserLoginHistory',
  components: {
    Preloader,
    APagination: () => import('@/components/common/APagination'),
  },
  mixins: [modal],
  data() {
    return {
      pending: false,
      list: [],
      total: 0,
      page: 1,
      quantity: 30
    }
  },
  computed: {
    ...mapGetters(['selectedUser']),
    paginationPages() {
      let pageNum = Math.trunc(this.total / this.quantity);
      if((this.total % this.quantity) > 0) {
        pageNum++;
      }
      return pageNum;
    }
  },
  methods: {
    ...mapActions(['getUsersSessions', 'setTempUser']),
    clear() {
      this.pending = false;
      this.list = [];
      this.page = 0;
      this.total = 0;
      this.setTempUser(null);
      this.modalActive = false;
    },
    close() {
      this.$modal.hide('logins-user-modal');
    },
    async setPagination(num) {
      this.page = num;
      await this.loadData();
    },
    async loadData() {
      this.pending = true;
      await this.getUsersSessions({
        per_page: this.quantity,
        current_page: this.page,
        id: this.selectedUser.id
      }).then(({data, pagination}) => {
        this.pending = false;
        this.list = data;
        if (data.length === 0 && this.page > 1) {
          this.$refs['pagination'].onChangePage(1);
        }
        // this.total = pagination.total;
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Login history',
          text: error.response.data.message,
          type: 'error'
        });
        console.error('Error get user sessions', error);
      });
    },
  }
}
</script>

<style scoped>

</style>
