<template>
  <div class="articles-list">
    <div class="block">
      <div v-if="user.role === 'USER'" class="articles-list-header block-wrapper header">
        <h1 class="title articles-list-title">Articles Overview</h1>
        <router-link to="/dashboard/articles/create" class="btn blue">+ Add new articles</router-link>
      </div>
      <div v-else class="articles-list-header admin block-wrapper header">
        <h1 class="title articles-list-title">Articles</h1>
        <Dropdown placeholder="All" :with-empty="true" :active="mapSelectedUser" :list="mapUsers"
                  @selected="selectUser"/>
        <button v-if="selectedUser" class="btn" @click="loginAs">Login</button>
        <!--        <button class="btn">PWD</button>-->
        <!--        <button class="btn">Res</button>-->
        <button v-if="selectedUser" class="btn" @click="openLogins">IPlog</button>
        <button v-if="selectedUser" class="btn" @click="openChangePassword">Pswrd</button>
        <ColorDropdown @selected="setColor"/>
        <button class="btn" @click="copyArticles">Copy</button>
        <button class="btn" @click="moveArticles">Move</button>
        <!--        <button class="btn">Change language</button>-->
        <!--        <button class="btn">cw&tru</button>-->
        <button class="btn" @click="toggleLock(true)">Lock</button>
        <button class="btn" @click="toggleLock(false)">Unlock</button>
        <button class="btn red" @click="deleteSelectedArticles()">Del</button>
        <button class="btn green" @click="setReadySelected">Ready</button>
      </div>
      <div class="table-container">
        <Preloader v-if="pending"/>
        <table v-if="list.length > 0" class="articles-list-table">
          <thead class="articles-list-table-header">
          <tr>
            <td class="check" v-if="user.role !== 'USER'">
              <ACheckbox :value="checkAllBool" @input="checkAll"/>
            </td>
            <td></td>
            <td>Title</td>
            <td>Water</td>
            <td>TW</td>
            <td>WT</td>
            <td>UWA</td>
            <td>UWW</td>
            <td>TRU</td>
            <!--            <td>TRU<br>TRANS</td>-->
            <td>Status</td>
            <td colspan="2">Date add/<br>change/ready</td>
          </tr>
          </thead>
          <tbody class="articles-list-table-content">
          <tr class="row" :style="{backgroundColor: article.color}" v-for="(article, idx) in list"
              :key="`article-${idx}`">
            <td class="col" v-if="user.role !== 'USER'">
              <ACheckbox :value="checkedArticle.indexOf(article.id) >= 0" @input="check(article.id)"/>
            </td>
            <td class="col col-id">#{{ idx + 1 + ((page - 1) * quantity) }}</td>
            <td class="col col-title">
              <div class="df">
                <span class="lang">{{ article.language }}</span>
                <router-link :to="`/dashboard/articles/${article.id}`">
                  <span>{{ article.title }}</span>
                </router-link>
              </div>
            </td>
            <td
              class="col"
              :class="article.revision && getColor(getPercent(article.revision.metrics.waterText.length, article.revision.metrics.wt))"
            >
              <div v-if="article.revision">
                <p>{{ article.revision.metrics.waterTextCount }}</p>
                <p>({{ getPercent(article.revision.metrics.waterTextCount, article.revision.metrics.wt) }}%)</p>
              </div>
              <p v-else>--</p>
            </td>
            <td
              class="col"
              :class="article.revision && getColor(getPercent(article.revision.metrics.targetWordsCount, article.revision.metrics.wt))"
            >
              <div v-if="article.revision">
                <p>{{ article.revision.metrics.targetWordsCount }}</p>
                <p>({{ getPercent(article.revision.metrics.targetWordsCount, article.revision.metrics.wt) }}%)</p>
              </div>
              <p v-else>--</p>
            </td>
            <td class="col">{{ article.revision ? article.revision.metrics.wt : '--' }}</td>
            <td
              class="col"
              :class="article.revision && getColor(article.revision.metrics.percentageUwa, true)"
            >
              <div v-if="article.revision">
                <p>{{ article.revision.metrics.absoluteUwa }}</p>
                <p>({{ Math.trunc(article.revision.metrics.percentageUwa) }}%)</p>
              </div>
              <p v-else>--</p>
            </td>
            <td
              class="col"
              :class="article.revision && getColor(article.revision.metrics.allTheUserTextsPercentageUwa, true)"
            >
              <div v-if="article.revision">
                <p>{{ article.revision.metrics.allTheUserTextsAbsoluteUwa }}</p>
                <p>({{ Math.trunc(article.revision.metrics.allTheUserTextsPercentageUwa) }}%)</p>
              </div>
              <p v-else>--</p>
            </td>
            <td class="col">
              <p>{{ article.revision ? getTextRu(article) : '--' }}</p>
            </td>
            <!--            <td class="col">{{ article.revision ? getTextRuTrans(article) : '&#45;&#45;' }}</td>-->
            <td
              class="col article-status"
              :class="{
              'c-green': article.status === 'READY' || article.status === 'PUB',
              'c-red': article.status === 'LOCKED'
            }"
            >{{ article.status === 'UNLOCKED' ? 'Wait' : article.status.toLowerCase() }}
            </td>
            <td class="col col-date">
              <p>{{ $moment(article.created_at).format('DD.MM.YYYY') }} /</p>
              <p><span class="c-green">{{ $moment(article.updated_at).format('DD.MM.YYYY') }}</span> /</p>
              <p v-if="article.ready_date" class="c-red">{{
                  $moment(article.ready_date.ready).format('DD.MM.YYYY')
                }}</p>
            </td>
            <td class="col col-buttons">
              <div class="df">
                <button @click="checkArticle(article.id)">
                  <Checklist />
                </button>
                <router-link :to="`/dashboard/articles/${article.id}`">
                  <Edit/>
                </router-link>
                <button v-if="user.role !== 'USER'" @click="deleteSelectedArticles([article.id])">
                  <Delete/>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else class="table-empty">
          <p v-if="!pending">Empty</p>
        </div>
        <APagination ref="pagination" :visible="list.length > 0" :page="page" :count="paginationPages"
                     :callback="setPagination"/>
      </div>
    </div>
    <div class="articles-list-info-wrapper df">
      <div class="articles-list-info-container">
        <p class="articles-list-info">
          <span>WT</span> - Etiam ultrices dictum ligula, ac auctor tortor pharetra et. Donec ullamcorper nibh aliquet,
          rhoncus massa ac, efficitur mi.
        </p>
        <p class="articles-list-info">
          <span>UWA</span> - Etiam ultrices dictum ligula, ac auctor tortor pharetra et. Donec ullamcorper nibh aliquet,
          rhoncus massa ac, efficitur mi.
        </p>
        <p class="articles-list-info">
          <span>UWW</span> - Etiam ultrices dictum ligula, ac auctor tortor pharetra et. Donec ullamcorper nibh aliquet,
          rhoncus massa ac, efficitur mi.
        </p>
      </div>
      <div v-if="selectedUser && user.role !== 'USER'" class="articles-list-note block">
        <Preloader v-if="pendingNotes"/>
        <div class="articles-list-note-header header">
          <h3 class="articles-list-note-title">User note</h3>
          <button class="article-page-edit" v-if="notes.length > 0" @click="deleteNotes">Delete all notes</button>
        </div>
        <div v-if="user.role !== 'USER'" class="article-comments-text-container">
          <span ref="textbox" role="textbox" contenteditable class="article-comments-text notes"></span>
          <div class="article-comments-btn-container">
            <button @click="addNote" class="btn blue">Add note</button>
          </div>
        </div>
        <ul v-if="notes.length > 0" class="articles-list-note-list">
          <li class="articles-list-note-item" v-for="(note, idx) in notes" :key="`note-${idx}`">
            <p class="articles-list-note-date">{{ $moment(note.created_at).format('DD MMM YYYY') }}</p>
            <p class="articles-list-note-text">{{ note.note }}</p>
          </li>
        </ul>
      </div>
    </div>
    <UserSelect :articles="checkedArticle" :title="selectUserTitle" :users="mapUsers" @select="onSelectUserAction"/>
    <UserLoginHistory/>
    <ChangePassModal />
    <ArticleMetricsModal :metrics="tempMetrics"/>
  </div>
</template>

<script>
import Edit from '@/assets/icons/edit.svg';
import Delete from '@/assets/icons/delete.svg';
import Checklist from '@/assets/icons/checklist.svg';
import {mapActions, mapGetters} from "vuex";
import {eventBus} from '@/main';
import Preloader from '@/components/common/Preloader';
import UserSelect from '@/components/modals/UserSelect';
import UserLoginHistory from '@/components/modals/UserLoginHistory';
import ArticleMetricsModal from '@/components/modals/ArticleMetricsModal';
import languages from '@/utils/languages';

export default {
  name: "ArticlesList",
  components: {
    ArticleMetricsModal,
    UserSelect,
    UserLoginHistory,
    ChangePassModal: () => import('@/components/modals/UserChangePassModal'),
    APagination: () => import('@/components/common/APagination'),
    Preloader,
    ColorDropdown: () => import("@/components/articles/ArticleColorsDropdown"),
    ACheckbox: () => import("@/components/common/ACheckbox"),
    Edit, Delete, Checklist,
    Dropdown: () => import('@/components/common/ADropdown')
  },
  data() {
    return {
      checkedArticle: [],
      notes: [],
      users: [],
      selectedUser: null,
      list: [],
      page: 1,
      quantity: 30,
      total: 0,
      pending: false,
      pendingNotes: false,
      actionArticle: '',
      tempMetrics: {},
      listLang: languages,
    }
  },
  computed: {
    ...mapGetters(['user', 'prevUser']),
    checkAllBool() {
      return this.list.length === this.checkedArticle.length
    },
    paginationPages() {
      let pageNum = Math.trunc(this.total / this.quantity);
      if ((this.total % this.quantity) > 0) {
        pageNum++;
      }
      return pageNum;
    },
    mapUsers() {
      return this.users.filter(u => u.role === 'USER').map(u => ({
        name: u.email,
        value: u.id
      }))
    },
    mapSelectedUser() {
      return this.selectedUser ? {
        name: this.selectedUser.email,
        value: this.selectedUser.id
      } : null;
    },
    selectUserTitle() {
      switch (this.actionArticle) {
        case 'copy':
          return 'Copy articles';
        case 'move':
          return 'Move articles';
        default:
          return 'Select user';
      }
    }
  },
  watch: {
    $route: {
      async handler(state, prevState) {
        if (this.user.role !== 'USER' && state.query.user !== prevState.query.user) {
          await this.loadArticles();
          if (state.query.user) {
            await this.getNotes();
          }
        }
      },
      deep: true
    },
    selectedUser() {
      console.log('this.selectedUser', this.selectedUser)
    }
  },
  methods: {
    ...mapActions([
      'setTempUser',
      'getAllArticles',
      'getArticles',
      'getArticlesUser',
      'setArticleColor',
      'toggleLockArticle',
      'deleteArticles',
      'setReadyArticle',
      'getAllUsers',
      'getUserNotes',
      'addUserNote',
      'deleteUserNotes',
      'moveUserArticles',
      'copyUserArticles',
      'checkArticleMetrics',
      'getArticle'
    ]),
    loginAs() {
      const {id, email, role, ip} = this.selectedUser;
      localStorage.setItem('prevUser', JSON.stringify({id, email, role, ip}))
      window.location = window.location.href.split('?')[0];
    },
    openLogins() {
      this.setTempUser(this.selectedUser);
      this.$modal.show('logins-user-modal');
    },
    openChangePassword() {
      this.setTempUser(this.selectedUser);
      this.$modal.show('change-pass-user-modal');
    },
    check(id) {
      const idx = this.checkedArticle.indexOf(id);
      if (idx >= 0) {
        this.checkedArticle.splice(idx, 1);
      } else {
        this.checkedArticle.push(id);
      }
    },
    checkAll() {
      this.checkedArticle = this.checkedArticle.length === 0 ? this.list.map(x => x.id) : [];
    },
    getPercent(val, total) {
      return Math.trunc((val / total) * 100)
    },
    getColor(val, reverse = false) {
      if (val < 33.3) {
        return reverse ? 'c-red' : 'c-green';
      } else if (val >= 33.3 && val <= 66.6) {
        return 'c-orange';
      } else {
        return reverse ? 'c-green' : 'c-red';
      }
    },
    getTextRu(article) {
      const {revision: {textru_metrics}} = article;
      if (textru_metrics && textru_metrics.textru_metrics) {
        const parsedTextRu = JSON.parse(textru_metrics.textru_metrics);
        return `${Math.trunc(+parsedTextRu.text_unique)}%`;
      } else {
        return '--';
      }
    },
    // getTextRuTrans(article) {
    //   const {revision: {textru_metrics}} = article;
    //   const metrics = textru_metrics.filter(tr => tr.language !== article.language).map((tr) => {
    //     if (tr.textru_metrics) {
    //       const parsedTextRu = JSON.parse(tr.textru_metrics);
    //       return `${tr.language.toUpperCase()}: ${Math.trunc(+parsedTextRu.text_unique)}%`;
    //     } else {
    //       return `${tr.language.toUpperCase()}: --`;
    //     }
    //   });
    //   return metrics.length > 0 ? metrics.join(' / ') : '--';
    // },
    async checkArticle(id) {
      let versions, text, user_id, language, article;
      this.pending = true;
      await this.getArticle(id).then(data => {
        versions = data.text_versions.sort((a, b) => this.$moment(b.updated_at).valueOf() - this.$moment(a.updated_at).valueOf());
        user_id = data.user_id;
        article = versions.find(a => a.is_active);
        text = article.text;
        language = article.language;
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Get article',
          text: error.response && error.response.data.message,
          type: 'error'
        });
      });
      if(this.pending) {
        await this.checkArticleMetrics({
          text_id: id,
          text,
          language,
          author_id: user_id
        }).then(data => {
          this.tempMetrics = data;
          this.pending = false;
          this.$modal.show('metrics-article-modal');
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: 'Check article',
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    async deleteSelectedArticles(id) {
      if (id) {
        this.pending = true;
        await this.deleteArticles(id).then(async () => {
          await this.loadArticles();
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: `Delete article`,
            text: error.response.data.message,
            type: 'error'
          });
        });
      } else if (this.checkedArticle.length > 0) {
        this.pending = true;
        await this.deleteArticles(this.checkedArticle).then(async () => {
          this.checkedArticle = [];
          await this.loadArticles();
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: `Delete articles`,
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    async toggleLock(lock) {
      if (this.checkedArticle.length > 0) {
        this.pending = true;
        await this.toggleLockArticle({
          texts_ids: this.checkedArticle,
          status: lock ? 'locked' : 'unlocked'
        }).then(async () => {
          this.checkedArticle = [];
          await this.loadArticles();
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: `Set ${lock ? 'locked' : 'unlocked'} article`,
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    async setReadySelected() {
      if (this.checkedArticle.length > 0) {
        this.pending = true;
        await this.setReadyArticle(this.checkedArticle).then(async () => {
          this.checkedArticle = [];
          await this.loadArticles();
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: `Set ready article`,
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    async setColor(color) {
      if (this.checkedArticle.length > 0) {
        this.pending = true;
        await this.setArticleColor({
          texts_ids: this.checkedArticle,
          color
        }).then(async () => {
          this.checkedArticle = [];
          await this.loadArticles();
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: 'Set article color',
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    async setPagination(num) {
      this.page = num;
      await this.loadArticles();
    },
    async loadArticles() {
      this.pending = true;
      if (this.user.role === 'USER' && !this.prevUser.id) {
        await this.getArticles({per_page: this.quantity, current_page: this.page}).then(({data, pagination}) => {
          this.pending = false;
          this.total = pagination.total;
          this.list = data;

          if (data.length === 0 && this.page > 1) {
            this.$refs['pagination'].onChangePage(1);
          }
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: 'Articles list',
            text: error.response.data.message,
            type: 'error'
          });
        });
      } else if (this.selectedUser || this.$route.query?.user) {
        await this.getArticlesUser({
          per_page: this.quantity,
          current_page: this.page,
          id: this.selectedUser?.id || this.$route.query?.user
        }).then(({data, pagination}) => {
          this.pending = false;
          this.total = pagination.total;
          this.list = data;

          if (data.length === 0 && this.page > 1) {
            this.$refs['pagination'].onChangePage(1);
          }
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: 'Articles list',
            text: error.response.data.message,
            type: 'error'
          });
        });
      } else {
        await this.getAllArticles({per_page: this.quantity, current_page: this.page}).then(({data, pagination}) => {
          this.pending = false;
          this.total = pagination.total;
          this.list = data;

          if (data.length === 0 && this.page > 1) {
            this.$refs['pagination'].onChangePage(1);
          }
        }).catch(error => {
          this.pending = false;
          this.$notify({
            title: 'Articles list',
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    async getUsers() {
      await this.getAllUsers().then(data => {
        this.users = data
      }).catch(error => {
        this.$notify({
          title: 'Get users',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    selectUser(user) {
      this.selectedUser = user ? this.users.find(u => u.id === user.value) : null;
      if (this.selectedUser) {
        this.$router.push({query: {...this.$route.query, user: this.selectedUser.id}})
      } else {
        const query = {...this.$route.query, user: null};
        delete query.user;
        this.$router.push({query})
      }
    },
    async getNotes() {
      this.pendingNotes = true;
      await this.getUserNotes(this.selectedUser.id).then(data => {
        this.notes = data;
        this.pendingNotes = false;
      }).catch(error => {
        this.pendingNotes = false;
        this.$notify({
          title: 'Get user notes',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    async addNote() {
      this.pendingNotes = true;
      const note = this.$refs.textbox.textContent;
      if (note.length > 0) {
        await this.addUserNote({
          id: this.selectedUser.id,
          note
        }).then(async () => {
          this.pendingNotes = false;
          this.$notify({
            title: 'Add user note',
            text: 'Note added successfully',
            type: 'success'
          });
          await this.getNotes();
        }).catch(error => {
          this.pendingNotes = false;
          this.$notify({
            title: 'Add user note',
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    },
    async deleteNotes() {
      this.pendingNotes = true;
      await this.deleteUserNotes(this.selectedUser.id).then(data => {
        this.notes = [];
        this.pendingNotes = false;
        this.$notify({
          title: 'Delete user notes',
          text: 'Notes deleted successfully',
          type: 'success'
        });
      }).catch(error => {
        this.pendingNotes = false;
        this.$notify({
          title: 'Delete user notes',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    copyArticles() {
      if (this.checkedArticle.length > 0) {
        this.actionArticle = 'copy';
        this.$modal.show('select-user-modal');
      }
    },
    moveArticles() {
      if (this.checkedArticle.length > 0) {
        this.actionArticle = 'move';
        this.$modal.show('select-user-modal');
      }
    },
    async onSelectUserAction(user) {
      if (this.actionArticle === 'copy') {
        await this.copyUserArticles({
          to: user.value,
          texts_ids: this.checkedArticle
        }).then(async () => {
          await this.loadArticles();
          this.$notify({
            title: 'Copy articles',
            text: 'Articles copied successfully',
            type: 'success'
          });
        }).catch(error => {
          this.$notify({
            title: 'Copy articles',
            text: error.response.data.message,
            type: 'error'
          });
        });
      } else if (this.actionArticle === 'move') {
        await this.moveUserArticles({
          to: user.value,
          texts_ids: this.checkedArticle
        }).then(async () => {
          await this.loadArticles();
          this.$notify({
            title: 'Move articles',
            text: 'Articles moved successfully',
            type: 'success'
          });
        }).catch(error => {
          this.$notify({
            title: 'Copy articles',
            text: error.response.data.message,
            type: 'error'
          });
        });
      }
    }
  },
  async created() {
    if (this.user.role !== 'USER') {
      await this.getUsers();
      const {user} = this.$route.query
      if (user) {
        const fUser = this.users.find(u => u.id === user);
        if (fUser && fUser.role === 'USER') {
          this.selectedUser = fUser;
          await this.getNotes();
        } else {
          this.$router.replace('/dashboard/articles')
        }
      }
    } else if (this.prevUser.id) {
      this.selectedUser = this.user;
    }
    eventBus.$on('onLoadArticles', async data => {
      await this.loadArticles();
    });
  }
}
</script>

<style scoped>

</style>
